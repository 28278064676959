
import { defineComponent } from 'vue';
import { URL_BASE_API_PÚBLICA } from '@/global'
import { llaves } from '@/fuentes'

export default defineComponent({
  name: 'Integracion',
  data: () => ({
		URL_BASE_API_PÚBLICA,
		llaves: llaves,
	}),
	mounted() {
		llaves.listar()
	},
});
