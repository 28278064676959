<template>

	<div class="contenedor">

		<div class="uk-overflow-auto">
			<table class="uk-table uk-table-small uk-table-divider">
				<caption>
					<h1>Llaves de integración <div uk-spinner v-if="llaves.estado.listando"></div></h1>
				</caption>
				<tbody>
					<tr v-for="l in llaves.estado.listado" :key="`itgrcn-llv-${l.identificador}`">
						<td>
							<div class="uk-inline">
								<button class="uk-form-icon uk-form-icon-flip" uk-icon="icon: bolt" @click="l.ver = !l.ver"></button>
								<input class="uk-input" :type="l.ver ? 'text' : 'password'" :value="l.identificador" disabled style="min-width: 550px">
							</div>
						</td>
						<td>
							{{ l.activa ? 'Activa' : 'Descartada' }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<hr>

		<div style="padding: 15px">

			<h4>Ejemplo de consumo de API con CURL:</h4>

			<pre>curl -i -X POST -H "Content-Type: application/json" \
-H "Authorization: <strong><em>&lt;LLAVE_DE_INTEGRACIÓN&gt;</em></strong>" \
-d '{"ente": {"identificador":"<strong><em>&lt;IDENTIFICADOR_DE_ENTE&gt;</em></strong>"}, \
	"grupoDeEntes": {"identificador":"<strong><em>&lt;IDENTIFICADOR_DE_GRUPO_DE_ENTE&gt;</em></strong>"}, \
	"variables": {"origen":{"direccionIP":"<strong><em>&lt;DIRECCIÓN_IP&gt;</em></strong>"}, \
		"dispositivo":{"identificador":"<strong><em>&lt;IDENTIFICADOR_ÚNICO_DE_DISPOSITIVO&gt;</em></strong>", \
		"plataforma":"<strong><em>&lt;PLATAFORMA_DE_DISPOSITIVO&gt;</em></strong>"}}}' \
{{ URL_BASE_API_PÚBLICA }}/eventos
			</pre>

			<p>Verifique los detalles de la API en la <router-link to="documentacion" append>documentación</router-link>.</p>

		</div>

	</div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { URL_BASE_API_PÚBLICA } from '@/global'
import { llaves } from '@/fuentes'

export default defineComponent({
  name: 'Integracion',
  data: () => ({
		URL_BASE_API_PÚBLICA,
		llaves: llaves,
	}),
	mounted() {
		llaves.listar()
	},
});
</script>